.seciton-9 .contiainer {
  margin-top: 15%;
}

.img-text {
  width: 100%;
  height: 300px;
  display: block;
  text-align: center;
  padding: 40% 5%;
  background: #262626;
  color: #fff;
  border-radius: 15px;
}

.img-text h2 {
  font-size: 25px;
  text-transform: uppercase;
  letter-spacing: 2px;
}

.img-text p {
  font-size: 14px;
}

.single-box {
  position: relative;
  margin-bottom: 30px;
}

.img-area {
  position: absolute;
  width: 80px;
  height: 80px;
  border: 5px solid #fff;
  overflow: hidden;
  top: 15px;
  left: 125px;
  border-radius: 50%;
}

.img-area img {
  width: 100%;
}

.carousel-indicators [data-bs-target] {
  background-color: #1192e5;
}

.carousel-indicators {
  bottom: -56px;
  color: #679ade;
}
