/* table .overflow {
  display: inline-block;
  width: 100%;
  height: 20px;
}

thead,
tbody {
  display: block;
}


table {
  width: 100%; 
}

tbody td,
thead th {
  width: 20%; 
}

tbody {
  height: 200px;
  overflow-y: auto; 
  overflow-x: hidden; 
} */
/* .table-container {
  overflow-x: auto;
} */

table {
  display: block;
  overflow-x: auto;
  white-space: nowrap;
}

tbody tr {
  white-space: nowrap;
  overflow: scroll;
}

table .overflow {
  overflow-y: scroll;
  max-height: 156px;
  display: block;
}
/* tbody {
  height: 200px;
  overflow-y: auto;
  overflow-x: hidden;
} */

.col-custom {
  width: 29.333333%;
}

.upload_image {
  width: 50%;
  display: inherit;
}

.services-card {
  padding: 20px;
  background-color: #fff;
  border: 2px solid #dadada;
}

.card-image-top {
  background-color: #d3d3d3;
  border-radius: 50%;
  height: 150px;
  margin-top: 20px;
  width: 150px;
}

.card-details .name {
  font-weight: 700;
}

.card-details .services p {
  font-size: 18px;
  display: flex;
}

.card-link i {
  color: #000;
  text-decoration: none;
}

.card-details .services p span,
.card-link i {
  margin-right: 10px;
}

.location {
  display: flex;
}

.location-details {
  margin-left: 10px;
}

.image_col {
  display: block;
  height: 100px;
  object-fit: contain;
}

.image_col img {
  width: 100%;
}

@media (max-width: 1025px) and (min-width: 767px) {
  .col-custom {
    width: 43.333333%;
  }
}

@media (max-width: 426px) {
  .col-custom {
    width: 89%;
  }
}
