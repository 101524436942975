.section-8 {
  background: linear-gradient(
    180deg,
    #1192e5 0%,
    #2c5f8e 99.97%,
    #274764 99.98%,
    #233749 99.99%,
    #1e1e1e 100%
  );
  margin: 90px 0;
}

.card_body .info {
  display: flex;
  float: right;
  color: lightgray;
}

.card_body .text {
  position: relative;
  bottom: 34px;
  width: fit-content;
  padding: 10px;
  color: white;
  font-size: 15px;
  font-weight: 800;
  background-color: #1192e5;
}

.blog {
  position: relative;
  top: 6rem;
}

.section-8 .head {
  position: relative;
  top: 100px;
  line-height: 1;
  color: #fff;
}

.section-8 .card_image img {
  width: 100%;
  object-fit: contain;
}

.section-8 .card-custom {
  box-shadow: 10px 10px 37px rgba(0, 0, 0, 0.25);
  padding: 20px;
  background-color: #fff;
}
